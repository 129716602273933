<template>
    <div>
        <!--begin::User-->
        <div class="card card-custom">
            <div class="card-header flex-wrap border-0 pt-6 pb-0">
                <div class="card-title m-0">
                    <h3 class="card-label m-0">
                        {{$t('api_configurations.api_endpoint_configurations')}}
                        <span class="text-muted pt-2 font-size-sm d-block"></span>
                    </h3>
                </div>
                <div class="card-toolbar">
                    <!--begin::Button-->
<!--                    <router-link :to="'/settings/api-endpoint-configurations/'+provider_id" class="btn btn-light font-weight-bolder">-->
<!--                        <v-icon>mdi-chevron-left</v-icon>-->
<!--                        {{$t('back')}}-->
<!--                    </router-link>-->
                    <!--end::Button-->
                </div>
            </div>

            <div class="card-body">
                <b-card-body class="p-0">
                        <div class="form-group row">

                            <div class="col-lg-6 mb-5">
                                <label>{{$t('api_configurations.service_provider')}}: </label>
                                <h5>{{service_provider}}</h5>
                            </div>

                            <div class="col-lg-6 mb-5">
                                <label>{{$t('api_configurations.protocol')}}</label>
                                <div class="input-group">
                                    <select name="" id="protocol" v-model="data.protocol_id" class="custom-select">
                                        <option v-for="row in protocol_list" :value="row.id" :key="row.id">
                                            {{ row.name }}
                                        </option>
                                    </select>
                                    <span v-if="validation && validation.protocol_id" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.protocol_id[0] }}
                                    </span>
                                </div>
                            </div>


                            <div class="col-lg-6 mb-5">
                                <label>{{$t('api_configurations.endpoint_url')}}<span class="text-danger">*</span></label>
                                <input type="text" v-model="data.endpoint_url" class="form-control" :class="validation && validation.endpoint_url ? 'is-invalid' : ''" :placeholder="$t('api_configurations.endpoint_url')"/>
                                <span v-if="validation && validation.endpoint_url" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.endpoint_url[0] }}
                                    </span>
                            </div>


                            <div class="col-lg-6 mb-5">
                                <label>{{$t('api_configurations.slug')}}<span class="text-danger">*</span></label>
                                <input type="text" v-model="data.slug" class="form-control" :class="validation && validation.slug ? 'is-invalid' : ''" :placeholder="$t('api_configurations.slug')"/>
                                <span v-if="validation && validation.slug" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.slug[0] }}
                                    </span>
                            </div>


                            <div class="col-lg-6 mb-5">
                                <label>&ensp;</label>
                                <b-form-checkbox size="lg" v-model="data.is_active" name="check-button" switch>{{$t('status')}}</b-form-checkbox>
                            </div>


                            <div class="col-lg-12 mb-5 mt-5">
                                <div class="bg-FFB803 repeater p-3 d-flex justify-content-between">
                                    <h6 class="mt-2">{{$t('api_configurations.param')}}</h6>
                                    <button type="button" class="btn btn-primary" @click="addRepeater"><i class="fas fa-plus"></i>{{$t('add_more')}}</button>
                                </div>
                                <!-- <h6 class="card-label mt-10">
                                    {{$t('api_configurations.param')}}
                                    <span class="text-muted pt-2 font-size-sm d-block"></span>
                                </h6> -->
                                <table class="table">
                                    <thead>
                                    <tr>
                                        <th scope="col">{{$t('api_configurations.attribute')}}</th>
                                        <th scope="col">{{$t('api_configurations.value')}}</th>
                                        <th scope="col">{{ $t('actions') }}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for="(item, index) in data.param" :key="index">
                                        <td>
                                            <input type="text" v-model="item.attribute" class="form-control" :placeholder="$t('api_configurations.attribute')"/>
                                        </td>
                                        <td>
                                            <input type="text" v-model="item.value" class="form-control" :placeholder="$t('api_configurations.value')"/>
                                        </td>
                                        <td>
                                            <button type="button" class="btn btn-link mr-2" @click="removeRepeater(index)"><i class="fas fa-trash text-danger"></i></button>
                                        </td>
                                    </tr>


                                    </tbody>
                                </table>
                            </div>

                        </div>
                    </b-card-body>
                <div class="card-footer pl-0 pr-0">
                    <div class="row">
                        <div class="col-lg-6">
                            <button type="button" class="btn btn-primary mr-2" @click="save">{{ $t('save') }}</button>
                        </div>
                    </div>
                </div>
            </div>


        </div>
        <!--end::User-->
    </div>
</template>

<script>
    import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
    import ApiService from "@/core/services/api.service";

    export default {

        data() {
            return {
                mainRoute: 'settings/api-endpoint-configurations',
                mainRouteDependency: 'base/dependency',

                service_provider_list: [],
                protocol_list: [
                    {id: 1, name: 'http'},
                    {id: 2, name: 'https'},
                ],

                idEdit: this.$route.params.id ? this.$route.params.id : '',
                provider_id: this.$route.params.provider_id ? this.$route.params.provider_id : '',
                data: {
                    endpoint_url: null,
                    service_provider_id: this.$route.params.provider_id,
                    protocol_id: null,
                    param: [{attribute: null, value: null}],
                    slug: null,
                    is_active: true,
                },
                repeater: {attribute: null, value: null},
                isEditing: false,
                validation: null,

                service_provider: null,
            };
        },
        watch: {
            service_provider_list: function (val) {
                if (val.length){
                    let _service_provider = val.filter(row=> row.id == this.provider_id);
                    if (_service_provider[0]){
                        this.service_provider = _service_provider[0].name;
                    }
                }
            }
        },
        methods: {
            save() {
                if (this.isEditing) {
                    this.update();
                } else {
                    this.create();
                }
            },

            create() {
                // this.data.is_active = this.data.is_active ? '1' : '0';

                ApiService.post(this.mainRoute, {
                    ...this.data,
                }).then((response) => {
                    this.validation = null;
                    this.$successAlert(response.data.message);
                    this.$router.push('/settings/api-endpoint-configurations/' + this.provider_id);
                }).catch((error) => {
                    this.validation = error.response ? error.response.data.errors : null;
                });
            },

            update() {
                // this.data.is_active = this.data.is_active ? '1' : '0';
                ApiService.put(this.mainRoute + '/' + this.idEdit, {
                    ...this.data,
                }).then((response) => {
                    this.validation = null;
                    this.$successAlert(response.data.message);
                    this.$router.push('/settings/api-endpoint-configurations/' + this.provider_id);
                }).catch((error) => {
                    this.validation = error.response ? error.response.data.errors : null;
                });
            },

            getData() {
                this.reloadUploadAttachment = false;
                ApiService.get(this.mainRoute + '/' + this.idEdit).then((response) => {
                    this.isEditing = true;
                    this.data = response.data.data;
                    if (response.data.data.param && response.data.data.param.length <= 0) {
                        this.addRepeater();
                    }
                });
            },
            addRepeater() {
                this.data.param.unshift(this.repeater);
                this.repeater = {attribute: null, value: null};
            },
            removeRepeater(index) {
                if (this.data.param.length > 1)
                    this.data.param.splice(index, 1);
            },
            getProviders() {
                ApiService.get(this.mainRouteDependency + "/services-providers").then((response) => {
                    this.service_provider_list = response.data.data;
                });
            },

        },

        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [
                {title: this.$t("MENU.Api_configurations"), route:'/settings/api-configurations'},
                {title: this.$t("MENU.Api_endpoint_configurations"), route:'/settings/api-endpoint-configurations/'+this.provider_id},
                {title: (this.idEdit ? this.$t('update') : this.$t('create'))}
                ]);

            this.getProviders();

            if (this.idEdit) {
                this.getData();
            }
        },
    };
</script>


